"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validated = void 0;
function validated(checkFn, _validityName) {
    const isValid = function (input) {
        return checkFn(input);
    };
    return [isValid, {}];
}
exports.validated = validated;
