import { FirebaseApp, initializeApp } from "firebase/app";
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import { Analytics, getAnalytics } from "firebase/analytics";

import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  onSnapshot,
  Firestore,
  Unsubscribe,
  CollectionReference,
  DocumentReference,
  // connectFirestoreEmulator,
} from "firebase/firestore";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
  Functions,
} from "firebase/functions";
import {
  ShortcutRecord,
  StatusRecord,
  UserProfile,
  WeatheredStripUser,
} from "@weatheredstrip/shared";

import { WS_FunctionTypes } from "@weatheredstrip/functions";

type WSFunctionNames = keyof WS_FunctionTypes;

type FunctionReturnValue<FunctionName extends WSFunctionNames> = Awaited<
  ReturnType<WS_FunctionTypes[FunctionName]>
>;
type FunctionParameters<FunctionName extends WSFunctionNames> = Parameters<
  WS_FunctionTypes[FunctionName]
>[0];

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const assembleUserFrom = (
  firebaseUser: User,
  profile: Partial<UserProfile>
): WeatheredStripUser => ({
  ...firebaseUser,
  profile,
});

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
  }
}

class Firebase {
  firebaseApp: FirebaseApp;
  analytics: Analytics;
  functions: Functions;
  auth: Auth;
  firestore: Firestore;
  servicesStatus: {};
  appCheck: AppCheck;
  serviceStatusUnsubscribe: Unsubscribe | undefined;

  constructor() {
    this.firebaseApp = initializeApp(config);

    this.functions = getFunctions(this.firebaseApp);
    this.auth = getAuth(this.firebaseApp);
    this.firestore = getFirestore(this.firebaseApp);

    if (process.env.NODE_ENV === "development") {
      window.FIREBASE_APPCHECK_DEBUG_TOKEN =
        process.env.REACT_APP_LOCAL_APP_CHECK_TOKEN;
      connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
      // connectFirestoreEmulator(this.firestore, "127.0.0.1", 5001);
    } else {
      this.analytics = getAnalytics(this.firebaseApp);
    }

    this.enableAppCheck();

    this.servicesStatus = {};
  }

  enableAppCheck() {
    this.appCheck = initializeAppCheck(this.firebaseApp, {
      provider: new ReCaptchaV3Provider(
        "6Le6R_IoAAAAAPj751HkoK7JhcgRPPeYWp37EBKr"
      ),
      isTokenAutoRefreshEnabled: true,
    });
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) => {
    /* Implementaiton to allow Async/Await
       and prevent UnCaught Error
    */
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCreds) => resolve(userCreds))
        .catch((reason) => reject(reason));
    });
  };

  /**
   * Signs out current user
   * @returns void
   */
  doSignOut = () => signOut(this.auth);

  doPasswordReset = (email: string) => sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = (password: string) => {
    if (!this.auth.currentUser) {
      throw Error("No user to be logged out.");
    }
    return updatePassword(this.auth.currentUser, password);
  };

  doAddProfileName = (name: string) => {
    if (!this.auth.currentUser) {
      throw Error("No user to be add profile name to.");
    }

    updateProfile(this.auth.currentUser, {
      displayName: name,
    });
  };

  doSendEmailVerification = () => {
    const redirectUrl = process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT;
    const currentUser = this.auth.currentUser;

    if (!currentUser) {
      throw Error("No user to send email verification to.");
    }

    if (process.env.NODE_ENV === "development" && redirectUrl) {
      return sendEmailVerification(currentUser, { url: redirectUrl });
    }

    return sendEmailVerification(currentUser);
  };

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (
    next: (user: WeatheredStripUser) => any,
    fallback: () => any
  ) =>
    onAuthStateChanged(this.auth, (authUser) => {
      if (authUser) {
        getDoc(this.userProfileReferenceFor(authUser.uid)).then((snapshot) => {
          let profile: Partial<UserProfile> = {};

          if (snapshot.exists()) {
            const data = snapshot.data() as UserProfile;

            profile = data;
          }

          next(assembleUserFrom(authUser, profile));
        });
      } else {
        fallback();
      }
    });

  // *** User API ***
  userProfileReferenceFor = (uid: string) => doc(this.profileCollection(), uid);

  presetsReferenceFor = (
    profile: DocumentReference<UserProfile, UserProfile>
  ) =>
    doc(profile, "shortcuts") as DocumentReference<
      ShortcutRecord[],
      ShortcutRecord[]
    >;

  profileCollection = () =>
    collection(this.firestore, "users") as CollectionReference<
      UserProfile,
      UserProfile
    >;

  // *** Callable Functions ***
  doHttpsCall = <T extends WSFunctionNames>(functionName: T) =>
    httpsCallable<FunctionParameters<T>, FunctionReturnValue<T>>(
      this.functions,
      functionName as string
    );

  // Status DB
  doSubscribeServicesStatus = (
    callback: (statuses: StatusRecord[]) => void
  ) => {
    this.serviceStatusUnsubscribe = onSnapshot(
      collection(this.firestore, "status"),
      (querySnapshot) => {
        const statuses: any[] = [];
        querySnapshot.forEach((document) => {
          statuses.push({
            ...document.data(),
          });
        });
        callback(statuses);
      }
    );
  };

  doUnsubscribeServicesStatus = () => {
    if (this.serviceStatusUnsubscribe) {
      this.serviceStatusUnsubscribe();
    }
  };
}

export default Firebase;
