import { Radar as IRadar, RadarType } from "@weatheredstrip/shared";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

type PropsType = {
  data: IRadar[];
};

const RadarContainer = styled.div<{ $currentGFA: number }>`
  position: relative;
  display: inline-block;

  & img {
    &:nth-of-type(${(props) => props.$currentGFA + 1}) {
      display: block;
    }
    display: none;
    max-width: 100%;
    height: auto;
  }
`;

const StyledRange = styled.input`
  width: 100%;
`;

const RangeSelector = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Time = styled.div`
  display: flex;
  font-family: "Source Code Pro", monospace;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Radar = ({ data }: PropsType) => {
  const radarLinks: string[] = [];

  const [currentRadarTime, setCurrentRadarTime] = useState(data.length - 1);

  const gfatimes = data.map((radar, index) => {
    radarLinks.push(radar.imageURL);
    const time = new Date(Date.parse(radar.sv + "Z"));
    const hours =
      String(time.getUTCHours()).length < 2
        ? "0" + String(time.getUTCHours())
        : time.getUTCHours();

    const minutes =
      String(time.getUTCMinutes()).length < 2
        ? "0" + String(time.getUTCMinutes())
        : time.getUTCMinutes();

    return (
      <Time>
        <div>{`${time.getUTCDate()}T${hours}:${minutes}Z`}</div>
        <div>{`${moment.utc(radar.sv).fromNow()}`}</div>
      </Time>
    );
  });

  const radar_array = radarLinks.map((link, index) => {
    return (
      <img
        useMap="#overlay"
        key={data[index].imageURL}
        src={link}
        alt="Graphical Area Forecast"
      />
    );
  });

  return (
    <RadarContainer $currentGFA={currentRadarTime}>
      {radar_array}
      <RangeSelector>
        <StyledRange
          type="range"
          min={0}
          max={data.length - 1}
          onChange={(e) => {
            setCurrentRadarTime(parseInt(e.target.value));
          }}
          value={currentRadarTime}
        ></StyledRange>
        {gfatimes[currentRadarTime]}
      </RangeSelector>
    </RadarContainer>
  );
};

const Radars = ({ data }: PropsType) => {
  const [selectedRadarType, setSelectedRadarType] = useState(RadarType.ECHOTOP);

  const selectedRadarTypeData = data.filter(
    (radarData) => radarData.type === selectedRadarType
  );

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=3.0"
        />
      </Helmet>
      <div className="subtitle">
        Radar
        <div className="linked-buttons">
          <button
            onClick={() => {
              setSelectedRadarType(RadarType.ECHOTOP);
            }}
            className={
              "linked-button " +
              (selectedRadarType === RadarType.ECHOTOP
                ? "primary"
                : "secondary")
            }
          >
            EchoTop
          </button>
          <button
            onClick={() => {
              setSelectedRadarType(RadarType.RAIN);
            }}
            className={
              "linked-button " +
              (selectedRadarType === RadarType.RAIN ? "primary" : "secondary")
            }
          >
            Rain
          </button>
          <button
            onClick={() => {
              setSelectedRadarType(RadarType.SNOW);
            }}
            className={
              "linked-button " +
              (selectedRadarType === RadarType.SNOW ? "primary" : "secondary")
            }
          >
            Snow
          </button>
        </div>
      </div>
      <div id="gfa">
        <Radar data={selectedRadarTypeData} />
      </div>
    </div>
  );
};

export default Radars;
