import React, { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";

import PrintView from "./components/printview";
import { Station, ValidIATACode, ValidICAOCode } from "@weatheredstrip/shared";
import { useLocation } from "react-router";
import qs from "qs";
import { logEvent } from "firebase/analytics";
import { FirebaseContext } from "../../firebase";

interface PropsType {
  data: Station[];
  selected: ValidIATACode | ValidICAOCode;
  onClick: (airportCode: string) => void;
}

function StationNav({ data, selected, onClick }: PropsType) {
  const firebase = useContext(FirebaseContext);
  const { search } = useLocation();

  const { stations: stationsSearchValue } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { stations: string };

  const stationNames = stationsSearchValue?.split(" ");

  const componentRef = useRef<HTMLDivElement>(null!);

  if (data) {
    const airports = data.filter((station) => station.name !== undefined);

    airports.sort((a, b) => {
      const aIndex = Math.max(
        ...a.codes.map((code) => stationNames?.indexOf(code))
      );
      const bIndex = Math.max(
        ...b.codes.map((code) => stationNames?.indexOf(code))
      );

      return aIndex - bIndex;
    });

    const renderedAirports = airports.map((airport) => {
      const divClass = airport.codes.includes(selected)
        ? "station station-selected"
        : "station";
      return (
        <button
          className={divClass}
          key={airport.codes[0]}
          onClick={() => {
            onClick(airport.codes[1]);
          }}
        >
          {airport.codes[1]}
        </button>
      );
    });

    return (
      <div id="navbar">
        <div className="navbar-content">
          {renderedAirports}
          <ReactToPrint
            trigger={() => (
              <button
                onClick={() => logEvent(firebase.analytics, "Print")}
                className="button primary print-button"
                key="print"
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
            bodyClass="print-body"
            pageStyle=""
          />
        </div>
        <div style={{ display: "none" }}>
          <PrintView data={data} ref={componentRef} />
        </div>
      </div>
    );
  } else {
    return <div id="navbar" />;
  }
}

export default StationNav;
