export const LANDING = "/";
export const SIGN_UP = "/signup";
export const REQUEST = "/request";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_SERVICE = "/terms-of-service";

const ROUTES = {
  LANDING,
  SIGN_UP,
  REQUEST,
  ACCOUNT,
  ADMIN,
  PASSWORD_FORGET,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
};

export default ROUTES;
