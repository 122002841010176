import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Badge,
  Button,
  CogIcon,
  Dialog,
  EditIcon,
  FormField,
  IconButton,
  Pane,
  Popover,
  Position,
  Spinner,
  TagInput,
  Text,
  TextInputField,
  TickCircleIcon,
  Tooltip,
  TrashIcon,
} from "evergreen-ui";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { FirebaseContext } from "../../../firebase";
import { useAuthUser } from "../../../hooks";
import {
  ShortcutRecord,
  ValidIATACode,
  ValidICAOCode,
  isIATACode,
  isICAOCode,
} from "@weatheredstrip/shared";
import { updateDoc } from "firebase/firestore";
import { PresetContextProvider, usePresetContext } from "./presetContext";

const PresetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #fffc;
  padding: 10px;
  border-radius: 5px;
  width: calc(70% - 50px);
  min-width: 200px;

  @media only screen and (max-width: 600px) {
    width: calc(80% - 50px);
  }
`;

const PresetsHeader = styled(Pane)`
  font-size: 1.2em;
`;

const PresetContainer = styled(Pane)`
  cursor: pointer;
`;

const PresetHeader = styled.div`
  margin-right: 10px;
  margin-left: 5px;
  flex: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const PresetTagging = styled(TagInput)`
  text-transform: uppercase;
`;

const PresetStations = styled.div`
  flex: 60%;
  justify-content: flex-end;
  display: flex;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PresetStation = styled(Badge)`
  margin: 0 2px;
`;

type CommonProps = {
  isShown: boolean;
  setShown: (isShown: boolean) => void;
};

type NoPresetProps = CommonProps & {
  preset?: undefined;
};

type PresetProps = CommonProps & {
  readonly preset: ShortcutRecord;
  presetIndex: number;
};

const removeDuplicates = (values: string[]) => {
  const newValues: string[] = [];

  values.forEach((newStation) => {
    const uppercaseStation = newStation.toUpperCase();

    if (!newValues.includes(uppercaseStation)) {
      newValues.push(uppercaseStation);
    }
  });

  return newValues;
};

const removeInvalidCodes = (
  values: string[]
): Array<ValidIATACode | ValidICAOCode> => {
  return values.filter(
    (value) => isIATACode(value) || isICAOCode(value)
  ) as Array<ValidIATACode | ValidICAOCode>;
};

const ModifyDialog = (props: NoPresetProps | PresetProps) => {
  const { isShown, setShown, preset } = props;

  const firebase = useContext(FirebaseContext);

  const user = useAuthUser();

  const [name, setName] = useState("");
  const [values, setValues] = useState<Array<ValidIATACode | ValidICAOCode>>(
    []
  );

  useEffect(() => {
    if (preset) {
      setName(preset.name);
      setValues(preset.airports ?? []);
    }
  }, [preset]);

  const handleSave = () => {
    if (user) {
      const userRef = firebase.userProfileReferenceFor(user.uid);
      let newShortcuts = user.profile.shortcuts ?? [];

      if (preset) {
        // modify preset
        newShortcuts[props.presetIndex] = {
          name: name,
          airports: values,
        };
      } else {
        // add preset
        newShortcuts.push({
          name: name,
          airports: values,
        });
      }

      updateDoc(userRef, {
        shortcuts: newShortcuts,
      }).then(() => {
        setShown(false);
      });
    }
  };

  const handleClose = () => {
    setShown(false);
    if (!preset) {
      setName("");
      setValues([]);
    }
  };

  const handleCancel = () => {
    if (preset) {
      if (preset.name !== name) {
        setName(preset.name);
      }

      if (
        preset.airports &&
        (preset.airports.length !== values.length ||
          preset.airports.some((element, index) => element !== values[index]))
      ) {
        setValues(preset.airports);
      }
    }

    setShown(false);
  };

  const separatorRegEx = /[ ,;.]/;

  return (
    <Dialog
      isShown={isShown}
      title={`${preset ? "Modify" : "Add"} a Preset`}
      onCloseComplete={handleClose}
      onConfirm={handleSave}
      onCancel={handleCancel}
    >
      <TextInputField
        label="Preset Name"
        description="Give your preset a short name."
        placeholder="Any text..."
        value={name ?? ""}
        onChange={(e) => setName(e.target.value)}
      />
      <FormField
        label="Preset Airports/Stations"
        description="ICAO codes to be searched when using the preset."
      >
        <PresetTagging
          width="100%"
          autoComplete="off"
          values={values}
          addOnBlur={true}
          separator={separatorRegEx}
          tagSubmitKey="space"
          inputProps={{ placeholder: "ICAO codes..." }}
          onChange={(eventValues: string[]) => {
            let allCapsValues = eventValues.map((value) => value.toUpperCase());

            if (allCapsValues.length > values.length) {
              allCapsValues = removeDuplicates(allCapsValues);
            }

            const validCodes = removeInvalidCodes(allCapsValues);

            setValues(validCodes);
          }}
        />
      </FormField>
    </Dialog>
  );
};

const AddPresetButton = () => {
  const [isDialogShown, setDialogShown] = useState(false);

  return (
    <>
      <Button
        background="gray90"
        display="flex"
        padding={5}
        alignItems="center"
        marginTop={5}
        borderRadius={5}
        justifyContent="center"
        onClick={() => setDialogShown(true)}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
        <Pane marginLeft={5}>Add Preset</Pane>
      </Button>
      <ModifyDialog isShown={isDialogShown} setShown={setDialogShown} />
    </>
  );
};

const Preset = ({
  disabled = false,
  index,
  onDelete,
  preset,
  showAlert = () => {},
  showButtons = false,
}: {
  disabled?: boolean;
  index: number;
  onDelete: (index: number) => Promise<void>;
  preset: ShortcutRecord;
  showAlert?: () => void;
  showButtons?: boolean;
}) => {
  const history = useHistory();
  const [modifyDialogShown, showModifyDialog] = useState(false);

  const handleClick = () => {
    if (showButtons) {
      // Save button
      showAlert();
    } else if (preset.airports?.length) {
      // Save button
      history.push(`/request?stations=${preset.airports.join(" ")}`);
    }
  };

  const handleEdit = () => {
    showModifyDialog(true);
  };

  return (
    <>
      <PresetContainer
        alignItems="center"
        background="gray90"
        borderRadius={5}
        disabled={disabled}
        display="flex"
        elevation={1}
        marginTop={5}
        onClick={handleClick}
        padding={5}
      >
        <Pane
          width="100%"
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <PresetHeader>{preset.name}</PresetHeader>
          {!showButtons && (
            <PresetStations>
              {preset.airports?.map((station, index) => (
                <PresetStation key={`station-${preset.name}-${index}`}>
                  {station}
                </PresetStation>
              ))}
            </PresetStations>
          )}
          {showButtons && (
            <Pane
              display="flex"
              flex="10%"
              justifyContent="flex-end"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                e.stopPropagation()
              }
            >
              <IconButton
                appearance="minimal"
                icon={EditIcon}
                onClick={handleEdit}
                size="small"
              />
              <Popover
                position={Position.TOP}
                content={({ close }) => (
                  <Pane
                    width={200}
                    padding={5}
                    display="flex"
                    flexDirection="column"
                  >
                    <Text textAlign="center">Confirm removal of: </Text>
                    <Text fontWeight={600} textAlign="center">
                      {preset.name}
                    </Text>
                    <Button
                      appearance="primary"
                      intent="danger"
                      marginTop={5}
                      onClick={async () => {
                        await onDelete(index);
                        close();
                      }}
                    >
                      Delete
                    </Button>
                  </Pane>
                )}
              >
                <IconButton
                  appearance="minimal"
                  icon={TrashIcon}
                  intent="danger"
                  size="small"
                />
              </Popover>
            </Pane>
          )}
        </Pane>
      </PresetContainer>
      <ModifyDialog
        isShown={modifyDialogShown}
        preset={preset}
        presetIndex={index}
        setShown={() => showModifyDialog(false)}
      />
    </>
  );
};

const PresetsList = ({ showButtons }: { showButtons: boolean }) => {
  const { presets, deletePreset } = usePresetContext();

  return (
    <>
      {presets &&
        presets?.length > 0 &&
        presets.map((shortcut, index) => (
          <Preset
            key={`shortcut-${index}`}
            index={index}
            preset={shortcut}
            onDelete={deletePreset}
            showButtons={showButtons}
          />
        ))}
      {presets && presets?.length < 4 && <AddPresetButton />}
    </>
  );
};

const UserPresets = () => {
  const user = useAuthUser();

  const [optionsShown, showOptions] = useState(false);
  const [saveAlertShown, showSaveAlert] = useState(false);

  useEffect(() => {
    if (saveAlertShown) {
      setTimeout(() => showSaveAlert(false), 700);
    }
  }, [saveAlertShown]);

  return (
    <PresetContextProvider>
      <PresetsContainer>
        <PresetsHeader
          alignItems="center"
          display="flex"
          paddingX={3}
          paddingBottom={10}
          borderBottom="1px #4445 solid"
        >
          <Pane flex={1}>Search Presets</Pane>
          <Tooltip
            position={Position.TOP}
            isShown={saveAlertShown}
            content="Confirm First"
            appearance="card"
          >
            <IconButton
              size="small"
              appearance="minimal"
              onClick={() => showOptions(!optionsShown)}
              icon={optionsShown ? TickCircleIcon : CogIcon}
              intent={optionsShown ? "success" : "none"}
            />
          </Tooltip>
        </PresetsHeader>
        {user && user.loading && (
          <Pane display="flex" justifyContent="center" marginTop={5}>
            <Spinner size={24} />
          </Pane>
        )}
        {user && !user.loading && <PresetsList showButtons={optionsShown} />}
        {!user && (
          <Text paddingX={3} marginTop={10}>
            Search presets are only available when logged in.
          </Text>
        )}
      </PresetsContainer>
    </PresetContextProvider>
  );
};

export default UserPresets;
