import React from "react";
import Firebase from "./firebase";

const FirebaseContext = React.createContext<Firebase>({} as Firebase);

export interface WithFirebaseProps {
  firebase: Firebase;
}

interface ComponentProps extends WithFirebaseProps {}

export const withFirebase =
  <P extends {}>(Component: React.ComponentType<ComponentProps>) =>
  (props: P) =>
    (
      <FirebaseContext.Consumer>
        {(firebase) => <Component {...props} firebase={firebase} />}
      </FirebaseContext.Consumer>
    );

export default FirebaseContext;
