import React, { forwardRef } from "react";
import Metars from "../../metars";
import Tafs from "../../tafs";
import {
  Airport,
  CanadianAirport,
  CanadianNOTAM,
  isCanadianAirport,
  NOTAM,
  NOTAMType,
  Station,
  STATION_TYPE_AIRPORT,
  ValidICAOCode,
  GFA,
  GFAType,
  isCanadianNOTAM,
} from "@weatheredstrip/shared";
import {
  NotamSchedule,
  formatEndValidity,
  formatStartValidity,
} from "../notam";

export function renderNotamValidity(notam: NOTAM) {
  return `${formatStartValidity(notam.startValidity)} - ${formatEndValidity(
    notam.endValidity
  )} ${notam.isEndEstimated ? "EST." : ""}`;
}

const renderNotam = (
  notams: NOTAM[] | CanadianNOTAM[] | null,
  type: NOTAMType
) => {
  if (notams) {
    const typed = notams.filter((notam) => notam.type === type);
    if (typed.length === 0) {
      return null;
    } else {
      return typed.map((notam, index) => {
        return (
          <div className="notif-text" key={index}>
            {notam && notam.link ? (
              <div>
                <a href={notam.link} rel="noopener noreferrer" target="_blank">
                  <strong>{notam.title} - </strong>
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </div>
            ) : (
              <div>
                <strong>{notam.title}</strong>
                <span>{renderNotamValidity(notam)}</span>
              </div>
            )}
            {isCanadianNOTAM(notam) &&
              notam.schedule?.length > 0 &&
              NotamSchedule(notam)}
            <div>{notam.notam}</div>
          </div>
        );
      });
    }
  }
};

// TODO: Be more specific with the types
const renderGpsNotams = (notams: NOTAM[] | null) => {
  if (notams !== null) {
    return notams.map((notam) => (
      <div className="notif-text" key={notam.title}>
        <div>
          <strong>{notam.title}</strong>
        </div>
        <div>{notam.notam}</div>
      </div>
    ));
  } else {
    return null;
  }
};

// TODO: Be more specific with the types
const renderFIRNotams = (notams: NOTAM[] | null) => {
  if (notams !== null) {
    return notams.map((notam) => {
      return (
        <div className="notif-text" key={notam.title}>
          {notam && notam.link ? (
            <div>
              <a href={notam.link} rel="noopener noreferrer" target="_blank">
                <strong>{notam.title} - </strong>
                <i className="fas fa-external-link-alt"></i>
              </a>
            </div>
          ) : (
            <div>
              <strong>{notam.title}</strong>
            </div>
          )}
          <div>{notam.notam}</div>
        </div>
      );
    });
  }
};

const renderAllGFA = (data: (Station | Airport | CanadianAirport)[]) => {
  const weatherGFAs: GFA[] = [];
  const icingGFAs: GFA[] = [];

  const recordedGFAs: string[] = [];

  data.forEach((station) => {
    if (isCanadianAirport(station)) {
      station.gfa?.forEach((gfa) => {
        if (!recordedGFAs.some((recordedURL) => gfa.imageURL === recordedURL)) {
          recordedGFAs.push(gfa.imageURL);
          if (gfa.type === GFAType.Cloud) {
            weatherGFAs.push(gfa);
          }

          if (gfa.type === GFAType.Icing) {
            icingGFAs.push(gfa);
          }
        }
      });
    }
  });

  const orderedGFAs: React.ReactElement[] = [];

  weatherGFAs.forEach((weatherGFA, index) => {
    orderedGFAs.push(
      <img
        key={weatherGFA.imageURL}
        src={weatherGFA.imageURL}
        alt={weatherGFA.imageURL}
      />
    );
    orderedGFAs.push(
      <img
        key={icingGFAs[index].imageURL}
        src={icingGFAs[index].imageURL}
        alt={icingGFAs[index].imageURL}
      />
    );
  });

  const groupedGFAs: Array<Array<React.ReactElement>> = [];

  for (let iter = 0; iter < orderedGFAs.length; iter += 6) {
    groupedGFAs.push(orderedGFAs.slice(iter, iter + 6));
  }

  return (
    <div className="print-station">
      <div className="station-name">GFAs</div>
      {groupedGFAs.map((gfaGroup, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="print-gfa-group">
            {gfaGroup.map((gfa, index) => gfa)}
          </div>
          <div className="page-break" />
        </React.Fragment>
      ))}
    </div>
  );
};

interface PropsType {
  data: Station[];
}

function isAirport(station: Station): station is Airport {
  return station.station_type === STATION_TYPE_AIRPORT;
}

const PrintView = forwardRef(
  (
    { data }: React.PropsWithRef<PropsType>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    let gfas: React.ReactElement;
    let contentArray: (React.JSX.Element | null)[];
    // since KGPS is always searched, length always >= 1
    if (data && data.length > 1) {
      gfas = renderAllGFA(data);

      contentArray = data.map((stationData) => {
        if (isAirport(stationData)) {
          const aerodromeNotams = renderNotam(
            stationData.notam_EN,
            "aerodrome"
          ) || (
            <div className="notif-text">
              No Aerodrome NOTAM found for this station
            </div>
          );
          const areaNotams = renderNotam(stationData.notam_EN, "area") || (
            <div className="notif-text">
              No Area NOTAM found for this station
            </div>
          );
          const FIRNotams =
            stationData.iso_country === "CA"
              ? renderNotam(stationData.notam_EN, "FIR") || (
                  <div className="notif-text">
                    No NOTAM for {stationData.FIR}.
                  </div>
                )
              : renderFIRNotams(
                  data.filter(
                    (firStation) => firStation.codes[0] === stationData.FIR
                  )[0].notam_EN
                ) || (
                  <div className="notif-text">
                    No NOTAM for {stationData.FIR}.
                  </div>
                );
          const nationalNotams = renderNotam(
            stationData.notam_EN,
            "national"
          ) || (
            <div className="notif-text">No National NOTAM at this time.</div>
          );
          return (
            <div ref={ref} key={stationData.codes[0]} className="print-station">
              <div className="station-name">
                {`${stationData.codes[0]} - ${stationData.name}`}
              </div>
              <div className="subtitle">METAR</div>
              <Metars data={stationData.metar} />
              <div className="subtitle">TAF</div>
              <Tafs data={stationData.taf} />
              <div className="subtitle">NOTAM</div>
              <div className="notam-type">Aerodrome</div>
              <div>{aerodromeNotams}</div>
              <div className="notam-type">Area</div>
              <div>{areaNotams}</div>
              <div className="notam-type">FIR</div>
              <div>{FIRNotams}</div>
              <div className="notam-type">National</div>
              <div>{nationalNotams}</div>
            </div>
          );
        } else if (
          stationData.type === "other" &&
          stationData.codes.indexOf("KGPS" as ValidICAOCode) >= 0
        ) {
          const gpsNotams = renderGpsNotams(stationData.notam_EN) || (
            <div className="notif-text">No GPS NOTAM at this time.</div>
          );
          return (
            <div ref={ref} key={stationData.codes[0]} className="print-station">
              <div className="station-name">{stationData.codes[0]}</div>
              <div className="subtitle">NOTAM</div>
              <div>{gpsNotams}</div>
            </div>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div ref={ref}>
          No content specified. Ensure a search was done before printing.
        </div>
      );
    }

    return (
      <div ref={ref}>
        {gfas}
        {contentArray}
      </div>
    );
  }
);

export default PrintView;
