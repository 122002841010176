"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISOCountryObject = exports.isISOCountry = exports.ISORegionObject = exports.isISORegion = exports.GENERIC_AIP_LINK = exports.ICAOCodeObject = exports.isICAOCode = exports.IATACodeObject = exports.isIATACode = exports.isCanadianAirport = exports.isAirport = exports.isCanadianNOTAM = void 0;
const CONSTANTS = __importStar(require("./constants"));
__exportStar(require("./constants"), exports);
__exportStar(require("./utils"), exports);
const type_utils_1 = require("./type_utils");
const validators_1 = require("./validators");
function isCanadianNOTAM(notam) {
    return Object.keys(notam).includes("QCode");
}
exports.isCanadianNOTAM = isCanadianNOTAM;
function isAirport(station) {
    return station.station_type === CONSTANTS.STATION_TYPE_AIRPORT;
}
exports.isAirport = isAirport;
const CanadaCountryCode = "CA";
function isCanadianAirport(station) {
    return isAirport(station) && station.iso_country === CanadaCountryCode;
}
exports.isCanadianAirport = isCanadianAirport;
_a = (0, type_utils_1.validated)(validators_1.iataCodeValidator, "ValidIATACode"), exports.isIATACode = _a[0], exports.IATACodeObject = _a[1];
_b = (0, type_utils_1.validated)(validators_1.icaoCodeValidator, "ValidICAOCode"), exports.isICAOCode = _b[0], exports.ICAOCodeObject = _b[1];
exports.GENERIC_AIP_LINK = "https://www.navcanada.ca/en/aeronautical-information/aip-canada/aip-canada-supplements-sup.aspx";
_c = (0, type_utils_1.validated)(validators_1.isoRegionValidator, "ValidISORegion"), exports.isISORegion = _c[0], exports.ISORegionObject = _c[1];
_d = (0, type_utils_1.validated)(validators_1.isoCountryValidator, "ValidISOCountry"), exports.isISOCountry = _d[0], exports.ISOCountryObject = _d[1];
