import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Heading,
  IconButton,
  Menu,
  Pane,
  SideSheet,
  MenuIcon,
  IconButtonAppearance,
  UserIcon,
} from "evergreen-ui";
import "./nav.css";

import { ToSDialogLink, PrivacyPolicyDialogLink } from "../Policies";
import SignInMenu from "../SignIn";
import { useAuthUser } from "../../hooks";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";

const MenuAuth = ({ onSelection }) => (
  <>
    <Menu.Item
      icon={UserIcon}
      to={ROUTES.ACCOUNT}
      is={RouterLink}
      onSelect={onSelection}
    >
      Account
    </Menu.Item>
    <SignOutButton />
  </>
);

const MenuNonAuth = () => <SignInMenu />;

const UserMenu = ({ onSelection }) => {
  const user = useAuthUser();

  return (
    <Pane
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Menu>
        <Menu.Group>
          <Heading display="flex" alignItems="center" justifyContent="center">
            {user ? user.displayName : "Anonymous"}
          </Heading>
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item
            icon="search"
            to={ROUTES.LANDING}
            is={RouterLink}
            onSelect={onSelection}
          >
            Search
          </Menu.Item>
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          {user ? <MenuAuth onSelection={onSelection} /> : <MenuNonAuth />}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item
            icon="code"
            is="a"
            href="https://devblog.weatheredstrip.com"
            target="_blank"
            onSelect={onSelection}
          >
            DevBlog
          </Menu.Item>
        </Menu.Group>
      </Menu>
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={20}
      >
        <PrivacyPolicyDialogLink />
        <ToSDialogLink />
      </Pane>
    </Pane>
  );
};

type PixelString = `${number}px`;

type NavigationPropsType = {
  top?: PixelString | number;
  bottom?: PixelString | number;
  left?: PixelString | number;
  right?: PixelString | number;
  appearance?: IconButtonAppearance;
};

const Navigation = ({
  top,
  right,
  bottom,
  left,
  appearance,
}: NavigationPropsType) => {
  const [navShown, setNavShown] = useState(false);

  return (
    <>
      <SideSheet
        isShown={navShown}
        onCloseComplete={() => setNavShown(false)}
        width={150}
      >
        <UserMenu onSelection={() => setNavShown(false)} />
      </SideSheet>
      <Pane
        position="absolute"
        right={right}
        top={top}
        left={left}
        bottom={bottom}
      >
        <IconButton
          onClick={() => setNavShown(true)}
          appearance={appearance || "minimal"}
          icon={MenuIcon}
          iconSize={24}
        />
      </Pane>
    </>
  );
};

export default Navigation;
