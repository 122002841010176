import React, { useEffect, useState } from "react";
import styled from "styled-components";

declare global {
  interface Window {
    kofiWidgetOverlay?: KofiWidgetOverlay;
  }
}

interface FloatingChatConfig {
  "floating-chat.core.pageId": string;
  "floating-chat.core.closer": string;
  "floating-chat.core.position.bottom-left": string;
  "floating-chat.cssId": string;
  "floating-chat.notice.text": string;
  "floating-chat.donatebutton.image": `http${string}`;
  "floating-chat.donateButton.background-color": string;
  "floating-chat.donateButton.text": string;
  "floating-chat.donateButton.text-color": string;
  "floating-chat.stylesheets": string;
}

interface WidgetConfig extends Partial<FloatingChatConfig> {
  type: "floating-chat";
}

interface KofiWidgetOverlay {
  draw: (
    pageId: string,
    config: Partial<WidgetConfig>,
    containerId?: string
  ) => void;
}

interface WindowWithKofi extends Window {
  kofiWidgetOverlay: KofiWidgetOverlay;
}

const KofiContainer = styled.div`
  &&&&&&&.kofiimg {
    margin-left: -4px;
  }
`;

const kofiWidgetId = "kofi-widget-ws";

const KofiWidget = () => {
  // store the load state with useState
  const [loaded, setLoaded] = useState(false);
  // use the useEffect hook to update state when the script loads
  useEffect(() => {
    if (!loaded) {
      // create script element for the Ko-Fi script
      const kofiScript = document.createElement("script");
      // set the src to the script from Ko-Fi's cdn
      kofiScript.src =
        "https://storage.ko-fi.com/cdn/scripts/overlay-widget.js";
      // set the async property to true
      kofiScript.async = true;
      // add a listener for the script loading and update state
      kofiScript.addEventListener("load", () => setLoaded(true));
      // append the script to the document body
      document.body.appendChild(kofiScript);
    }
  }, [setLoaded, loaded]);

  // add another useEffect to draw the widget
  useEffect(() => {
    if (!loaded) return; // return out if not loaded

    const windowWithKofi = window as WindowWithKofi;

    windowWithKofi.kofiWidgetOverlay.draw(
      "weatheredstrip",
      {
        type: "floating-chat",
        "floating-chat.donateButton.text": "Support WS!",
        "floating-chat.donateButton.background-color": "$00b9fe",
        "floating-chat.donateButton.text-color": "#fff",
        "floating-chat.donatebutton.image":
          "https://storage.ko-fi.com/cdn/brandasset/kofi_p_logo_nolabel.png",
        "floating-chat.stylesheets": `["https://fonts.googleapis.com/css?family=Nunito:400,700,800&display=swap", "${process.env.PUBLIC_URL}/kofi-widget.css"]`,
      },
      kofiWidgetId
    );
  }, [loaded]); // the hook will depend on the loaded state

  return <KofiContainer id={kofiWidgetId} />;
};

export default KofiWidget;
