import { METAR } from "@weatheredstrip/shared";
import React from "react";

type PropsType = {
  data: METAR[] | null;
};

function Metars({ data }: PropsType) {
  let metars: React.JSX.Element | React.JSX.Element[];

  if (data && data.length > 0) {
    metars = data.map((metar, index) => {
      return (
        <div className="notif-text" key={index}>
          {metar}
        </div>
      );
    });
  } else {
    metars = (
      <div>
        No METAR is issued for this station. If you see this error message and
        expect to see METARs for this station, please submit a bug report.
      </div>
    );
  }

  return <div className="Metars">{metars}</div>;
}

export default Metars;
