import { LocationDescriptor } from "history";
import React, { ComponentProps } from "react";
import { useHistory } from "react-router";

interface PropsType extends ComponentProps<"button"> {
  to: LocationDescriptor;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const LinkButton = (props: PropsType) => {
  const history = useHistory();
  const {
    to,
    onClick,
    children,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;
  return (
    <button
      {...rest}
      onClick={(event) => {
        history.push(to);
        onClick && onClick(event);
      }}
    >
      {children}
    </button>
  );
};

export default LinkButton;
