import { appFirebase } from "../../firebase";

export const fetchStations = async (stations: string[]) => {
  try {
    return appFirebase.doHttpsCall("stations")({ stations });
  } catch (err) {
    // Getting the Error details.
    var code = err.code;
    var message = err.message;
    var details = err.details;
    console.error(code, message);
    console.error(details);
    throw err;
  }
};
