import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext from "./context";
import { withFirebase } from "../firebase";
import * as ROUTES from "../constants/routes";
import { WithFirebaseProps } from "../firebase/context";
import { RouteComponentProps } from "react-router";
import { WeatheredStripUser } from "@weatheredstrip/shared";

const withAuthorization =
  (condition: (user: WeatheredStripUser) => boolean) =>
  (Component: React.ElementType) => {
    class Authorization extends React.Component<
      WithFirebaseProps & RouteComponentProps<any>
    > {
      listener() {
        throw new Error("Method not implemented.");
      }

      componentDidMount() {
        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            if (!condition(authUser)) {
              this.props.history.push(ROUTES.LANDING);
            }
          },
          () => this.props.history.push(ROUTES.LANDING)
        );
      }

      componentWillUnmount() {
        this.listener();
      }

      render() {
        return (
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser && condition(authUser) ? (
                <Component {...this.props} />
              ) : null
            }
          </AuthUserContext.Consumer>
        );
      }
    }

    return compose(withRouter, withFirebase)(Authorization);
  };
export default withAuthorization;
