import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../firebase";
import { WithFirebaseProps } from "../firebase/context";
import { WeatheredStripUser } from "@weatheredstrip/shared";

const withAuthentication = (Component) => {
  class Authentication extends React.Component<
    WithFirebaseProps,
    { authUser: WeatheredStripUser | null }
  > {
    listener: any;

    constructor(props) {
      super(props);

      const localUser = localStorage.getItem("authUser") as string | null;

      this.state = {
        authUser: localUser ? JSON.parse(localUser) : null,
      };
    }
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem("authUser");
          this.setState({ authUser: null });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(Authentication);
};
export default withAuthentication;
