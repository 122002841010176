"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isoCountryValidator = exports.isoRegionValidator = exports.iataCodeValidator = exports.icaoCodeValidator = void 0;
function icaoCodeValidator(input) {
    let valid = true;
    valid = valid && input.length === 4;
    // First letter cannot be  I, J, Q, X
    valid = valid && input.match(/[A-HK-PR-W]{1}[A-Z0-9]{3}/) !== null;
    return valid;
}
exports.icaoCodeValidator = icaoCodeValidator;
function iataCodeValidator(input) {
    let valid = true;
    valid = valid && input.length === 3;
    valid = valid && input.match(/[A-Z]{3}/) !== null;
    return valid;
}
exports.iataCodeValidator = iataCodeValidator;
function isoRegionValidator(input) {
    let valid = true;
    valid = valid && input.length === 5;
    valid = valid && input.match(/[A-Z]{2}-[A-Z]{2}/) !== null;
    return valid;
}
exports.isoRegionValidator = isoRegionValidator;
function isoCountryValidator(input) {
    let valid = true;
    valid = valid && input.length === 2;
    valid = valid && input.match(/[A-Z]{2}/) !== null;
    return valid;
}
exports.isoCountryValidator = isoCountryValidator;
