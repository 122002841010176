import {
  GFA as IGFA,
  GFAType,
  AirportDocument,
  GFARegion,
} from "@weatheredstrip/shared";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import overlayAreas from "./extracted_map.json";
import styled from "styled-components";
import { calculate_GFA_coordinates } from "../../utils/lambert";

type PropsType = {
  data: IGFA[];
  selected: AirportDocument;
};

const StyledSVG = styled.svg`
  & circle {
    fill: black;
    opacity: 0.01;

    &.selected_airport {
      fill: orangered;
      opacity: 1;
    }
  }
`;

const SVG = ({
  region,
  selected,
}: {
  region: GFARegion;
  selected: AirportDocument;
}) => {
  const coordinates = calculate_GFA_coordinates(
    Number(selected.longitude_deg),
    Number(selected.latitude_deg),
    region
  );

  return (
    <StyledSVG viewBox="0 0 758 609">
      {overlayAreas[region].map((area) => (
        <circle key={area.alt} cx={area.coords.x} cy={area.coords.y} r={4}>
          <title>{area.title}</title>
        </circle>
      ))}
      {
        <circle
          className="selected_airport"
          key={selected.icao_code}
          cx={coordinates[0]}
          cy={coordinates[1]}
          r={4.5}
        >
          <title>{selected.icao_code}</title>
        </circle>
      }
    </StyledSVG>
  );
};

const GFAContainer = styled.div<{ $currentGFA: number }>`
  position: relative;
  display: inline-block;

  & img {
    &:nth-of-type(${(props) => props.$currentGFA + 1}) {
      display: block;
    }
    display: none;
    max-width: 100%;
    height: auto;
  }

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
`;

const GFA = ({ data, selected }: PropsType) => {
  const [typeGFA, setType] = useState<GFAType>(GFAType.Cloud);

  const gfaRegions: GFARegion[] = useMemo(() => {
    const regions: GFARegion[] = [];
    data.forEach((gfa) => {
      if (!regions.includes(gfa.region)) {
        regions.push(gfa.region);
      }
    });
    return regions;
  }, [data]);

  gfaRegions.sort();

  const [currentRegion, setCurrentRegion] = useState(gfaRegions[0]);

  useEffect(() => {
    if (!gfaRegions.includes(currentRegion)) {
      setCurrentRegion(gfaRegions[0]);
    }
  }, [gfaRegions, currentRegion]);

  const gfas = data.filter(
    (singleGFA) =>
      singleGFA.type === typeGFA &&
      (gfaRegions.length > 1 ? singleGFA.region === currentRegion : true)
  );
  const gfaLinks = gfas.map((gfa) => gfa.imageURL);

  const [currentGFA, setCurrentGFA] = useState(0);

  const gfatimes = gfas.map((gfa, index) => {
    const time = new Date(Date.parse(gfa.sv + "Z"));
    const hours =
      String(time.getUTCHours()).length < 2
        ? "0" + String(time.getUTCHours())
        : time.getUTCHours();

    const minutes =
      String(time.getUTCMinutes()).length < 2
        ? "0" + String(time.getUTCMinutes())
        : time.getUTCMinutes();

    const className = index === currentGFA ? "primary" : "secondary";

    return (
      <button
        key={gfa.sv}
        className={"button " + className}
        onClick={() => {
          setCurrentGFA(index);
        }}
      >
        {time.getUTCDate() + "T" + hours + minutes + "Z"}
      </button>
    );
  });

  const gfaRegionSelector = gfaRegions.map((region, index) => {
    const className = region === currentRegion ? "primary" : "secondary";
    return (
      <button
        key={region}
        className={"button " + className}
        onClick={() => {
          setCurrentRegion(region);
        }}
      >
        {region}
      </button>
    );
  });

  const gfa_array = gfaLinks.map((link, index) => {
    return (
      <img
        useMap="#overlay"
        key={gfas[index].imageURL}
        src={link}
        alt="Graphical Area Forecast"
      />
    );
  });

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=3.0"
        />
      </Helmet>
      <div className="subtitle">
        GFA
        <div className="linked-buttons">
          <button
            onClick={() => {
              setType(GFAType.Cloud);
            }}
            className={
              "linked-button " +
              (typeGFA === GFAType.Cloud ? "primary" : "secondary")
            }
          >
            Cloud
          </button>
          <button
            onClick={() => {
              setType(GFAType.Icing);
            }}
            className={
              "linked-button " +
              (typeGFA === GFAType.Icing ? "primary" : "secondary")
            }
          >
            Icing
          </button>
        </div>
      </div>
      <div id="gfa">
        <GFAContainer $currentGFA={currentGFA}>
          {gfa_array}
          <SVG region={currentRegion} selected={selected} />
        </GFAContainer>
        {gfaRegions.length > 1 && (
          <div className="gfa-buttons">{gfaRegionSelector}</div>
        )}
        <div className="gfa-buttons">{gfatimes}</div>
      </div>
    </div>
  );
};

export default GFA;
