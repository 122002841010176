import { ThunkStatus } from "../../store/constants";
import { createAppSlice } from "../../store/createAppSlice";
import { fetchStations } from "./queryAPI";
import { Station, ValidIATACode, ValidICAOCode } from "@weatheredstrip/shared";

export interface QuerySliceState {
  status: ThunkStatus;
  data: Station[];
  stations: string[];
  timestamp: string | null;
}

const initialState: QuerySliceState = {
  status: ThunkStatus.Idle,
  data: [],
  stations: [],
  timestamp: null,
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const querySlice = createAppSlice({
  name: "query",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    clear: create.reducer(() => {
      return initialState;
    }),
    // The function below is called a thunk and allows us to perform async logic. It
    // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
    // will call the thunk with the `dispatch` function as the first argument. Async
    // code can then be executed and other actions can be dispatched. Thunks are
    // typically used to make async requests.
    query: create.asyncThunk(
      async (stations: (ValidIATACode | ValidICAOCode)[]) => {
        const response = await fetchStations(stations);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      },
      {
        pending: (state) => {
          state.status = ThunkStatus.Pending;
        },
        fulfilled: (state, action) => {
          state.data = action.payload;
          state.stations = action.meta.arg;
          state.timestamp = new Date().toUTCString();
          state.status = ThunkStatus.Idle;
        },
        rejected: (state) => {
          state.status = ThunkStatus.Failed;
        },
      }
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectData: (query) => query.data,
    selectStatus: (query) => query.status,
    selectStations: (query) =>
      query.stations as (ValidIATACode | ValidICAOCode)[],
    selectTimestamp: (query) => query.timestamp,
  },
});

// Action creators are generated for each case reducer function.
export const { query, clear } = querySlice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { selectData, selectStatus, selectTimestamp, selectStations } =
  querySlice.selectors;
