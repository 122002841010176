import { ValidIATACode, ValidICAOCode } from "@weatheredstrip/shared";
import { LocationDescriptorObject } from "history";
import ROUTES from "../constants/routes";

export function buildRequestURL(
  stations: (ValidIATACode | ValidICAOCode)[]
): LocationDescriptorObject {
  return {
    pathname: ROUTES.REQUEST,
    search: "?stations=" + stations.join(" "),
  };
}
