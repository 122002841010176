import React from "react";
import {
  CanadianNOTAM,
  NOTAM,
  NOTAMType,
  NOTAM_TYPES,
  NOTAM_TYPE_OTHER,
} from "@weatheredstrip/shared";
import { Notam } from "./components/notam";
import styled from "styled-components";

type PropsType = {
  data: (NOTAM | CanadianNOTAM)[] | null;
  selectedType: NOTAMType;
  onTypeSelection: (selectedType: NOTAMType) => void;
  dataLength: Record<NOTAMType, number | null>;
};

const SelectorStyle = styled.div`
  display: inline-flex;
  align-items: center;
`;

const SelectorButtonStyle = styled.button`
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-right: 1px solid #fff;
`;

const SelectorButtonCount = styled.div`
  display: inline-flex;
  background-color: #a0a8b0;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 5px;
  font-size: 0.85rem;
  align-items: center;
  height: 100%;
  font-weight: 600;
  font-family: "Source Code Pro", monospace;
  line-height: 1.1rem;
`;

const SelectorButton = (props: {
  className: string;
  onClick: () => void;
  disabled: boolean;
  count: number;
  children: React.ReactNode;
}) => {
  return (
    <SelectorStyle>
      <SelectorButtonStyle
        className={props.className}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </SelectorButtonStyle>
      <SelectorButtonCount>{props.count}</SelectorButtonCount>
    </SelectorStyle>
  );
};

function Notams({
  data,
  selectedType,
  onTypeSelection,
  dataLength,
}: PropsType) {
  const typesFormated = NOTAM_TYPES.map((type, index) => {
    let className: string;
    let isDisabled = false;

    if (dataLength && !dataLength[type]) {
      isDisabled = true;
    }
    if (selectedType) {
      if (type === selectedType) {
        className = "button primary";
      } else {
        className = "button secondary";
      }
    } else {
      if (index === 0) {
        className = "button primary";
      } else {
        className = "button secondary";
      }
    }

    const formattedName =
      type !== NOTAM_TYPE_OTHER ? type[0].toUpperCase() + type.slice(1) : "GPS";

    const formattedQuantity =
      dataLength && dataLength[type] ? dataLength[type] : 0;

    return (
      <SelectorButton
        disabled={isDisabled}
        className={className}
        onClick={() => onTypeSelection(type)}
        key={type}
        count={formattedQuantity ?? 0}
      >
        {formattedName}
      </SelectorButton>
    );
  });

  return (
    <div id="notams">
      <div id="notam-header">
        <div className="subtitle">NOTAM</div>
        <div className="button-selection">{typesFormated}</div>
      </div>
      <div className="notams-list">
        {data?.map((notam) => {
          return <Notam notam={notam} key={notam.title} />;
        })}
      </div>
    </div>
  );
}

export default Notams;
