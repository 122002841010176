import React from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import "./index.css";
import { Provider } from "react-redux";

import { FirebaseContext, appFirebase } from "./firebase";

import { store } from "./store";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { StatusProvider } from "./context/status";

import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://f4af60844dfde2b55775e3c1ab34b363@o4506651410104320.ingest.sentry.io/4506651416592384",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"], // TODO add server (i.e. '/^https:\/\/yourserver\.io\/api/' )
        shouldCreateSpanForRequest: (url) => {
          // Do not create spans for outgoing requests to a `/firestore/` endpoint
          // TODO: adjust this to include some firestore requests but not all.
          return !url.match(/firestore.googleapis.com/);
        },
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
  });
}

const root = createRoot(document.getElementById("app") as HTMLElement);

root.render(
  <>
    <Helmet>
      <title>Weathered Strip</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Gather all required flight
          planning documentation in one place. Weathered Strip provides NOTAMs,
          METARs/TAFs for each requested airport as well as GFAs for canadian airports."
      />
      <meta name="author" content="Greg Hamel" />
      <meta name="twitter:title" content="Weathered Strip" />
      <meta
        property="og:description"
        content="Gather all required flight
          planning documentation in one place. Weathered Strip provides NOTAMs,
          METARs/TAFs for each requested airport as well as GFAs for canadian airports."
      />
      <meta property="og:title" content="Weathered Strip" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      />
      <meta name="theme-color" content="#000000" />
    </Helmet>
    <Provider store={store}>
      <FirebaseContext.Provider value={appFirebase}>
        <StatusProvider>
          <App />
        </StatusProvider>
      </FirebaseContext.Provider>
    </Provider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
