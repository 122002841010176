import React, { useEffect } from "react";

import { toaster } from "evergreen-ui";

import { StatusRecord } from "@weatheredstrip/shared";
import { useStatusContext } from "../../context/status";
import { usePrevious } from "../../hooks";

const warnServiceDown = (serviceName: string) => {
  toaster.warning(`The ${serviceName} service appears to be offline`, {
    id: serviceName,
    description:
      "The team has been notified, and will investigate as soon as possible.",
    duration: 3600, // Postpone automatic closing of the toaster
  });
};

const StatusPopup = ({ record }: { record: StatusRecord }) => {
  const previous = usePrevious(record);

  useEffect(() => {
    if (previous?.isFunctional === undefined || previous.isFunctional) {
      if (!record.isFunctional) {
        warnServiceDown(record.serviceName);
      }
    } else if (record.isFunctional && !previous.isFunctional) {
      toaster.success(`The ${record.serviceName} service is now online`, {
        id: record.serviceName,
      });
    }
  }, [record.isFunctional, record.serviceName, previous?.isFunctional]);

  return null;
};

const StatusReporter = () => {
  const { records } = useStatusContext();

  return (
    <>
      {records.map((statusRecord) => (
        <StatusPopup key={statusRecord.serviceName} record={statusRecord} />
      ))}
    </>
  );
};
export default StatusReporter;
