import React, { useState } from "react";
import { Pane, Text } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "./landing.css";

import Navigation from "../../components/Navigation";
import { PrivacyPolicyDialog, ToSDialog } from "../../components/Policies";

import UserPresets from "./components";
import KofiWidget from "../../components/Kofi";
import { buildRequestURL } from "../../utils/path";
import {
  ValidIATACode,
  ValidICAOCode,
  isIATACode,
  isICAOCode,
} from "@weatheredstrip/shared";

const year = new Date();

const LandingPage = ({ privacy = false, terms = false }) => {
  const [isPrivacyShown, setPrivacy] = useState(privacy);
  const [isTermsShown, setTerms] = useState(terms);
  return (
    <>
      <KofiWidget />
      <Navigation top="20px" right="20px" appearance="default" />
      <div className="landing-page">
        <div className="landing-content">
          <div className="logo-title">
            <img className="landing-logo" src={"/logo.svg"} alt="App Logo" />
            <div className="landing-title">Weathered Strip</div>
          </div>
          <SearchForm />
          <UserPresets />
        </div>
        <Pane
          position="absolute"
          bottom={20}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text color="white" size={300}>
            v{process.env.REACT_APP_VERSION}
          </Text>
          <Text color="white" size={300}>
            By Greg Hamel &copy; {year.getUTCFullYear()}
          </Text>
        </Pane>
      </div>
      <PrivacyPolicyDialog
        isShown={isPrivacyShown}
        onCloseComplete={() => setPrivacy(false)}
      />
      <ToSDialog
        isShown={isTermsShown}
        onCloseComplete={() => setTerms(false)}
      />
    </>
  );
};

const SearchForm = () => {
  const searchInitialState = "";
  const [search, setSearch] = useState(searchInitialState);

  const history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();

    const parsedSearch = search
      .trim()
      .split(" ")
      .filter(
        (maybeStation) => isICAOCode(maybeStation) || isIATACode(maybeStation)
      ) as (ValidIATACode | ValidICAOCode)[];

    const request = buildRequestURL(parsedSearch);
    setSearch(searchInitialState);
    history.push(request);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.toUpperCase());
  };

  return (
    <form onSubmit={onSubmit} className="landing-search">
      <input
        className="landing-search-input"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        name="search"
        placeholder="ICAO or IATA codes"
        value={search}
        onChange={onChange}
      />
      <button className="landing-search-button" type="submit">
        <FontAwesomeIcon
          className="landing-search-button-icon"
          icon={faSearch}
          size="2x"
          color="#282c34"
        />
      </button>
    </form>
  );
};

export default LandingPage;
