import { ShortcutRecord } from "@weatheredstrip/shared";
import { useAuthUser } from "../../../hooks";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FirebaseContext } from "../../../firebase";
import { Unsubscribe, onSnapshot, updateDoc } from "firebase/firestore";

interface IPresetContext {
  presets: ShortcutRecord[] | null;
  deletePreset: (id: number) => Promise<void>;
}

const PresetContext = createContext({
  deletePreset: (id) => Promise.resolve(),
} as IPresetContext);

export const PresetContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [presets, setPresets] = useState<ShortcutRecord[] | null>(null);
  const user = useAuthUser();
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;

    if (user?.uid) {
      unsubscribe = onSnapshot(
        firebase.userProfileReferenceFor(user.uid),
        (snapshot) => {
          if (snapshot.exists()) {
            const { shortcuts } = snapshot.data();

            setPresets(shortcuts);
          }
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firebase, user?.uid]);

  const deletePreset = useCallback(
    (index: number) => {
      if (user?.uid && presets) {
        const userRef = firebase.userProfileReferenceFor(user.uid);

        presets.splice(index, 1);

        return updateDoc(userRef, { shortcuts: presets });
      }

      return Promise.resolve();
    },
    [firebase, user?.uid, presets]
  );

  return (
    <PresetContext.Provider value={{ presets, deletePreset }}>
      {children}
    </PresetContext.Provider>
  );
};

export const usePresetContext = (): IPresetContext => useContext(PresetContext);
