import React, { useContext, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Alert,
  Pane,
  TextInput,
  Button,
  Link,
  Card,
  Heading,
} from "evergreen-ui";

import { FirebaseContext } from "../../firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetLink = () => (
  <Link is={RouterLink} to={ROUTES.PASSWORD_FORGET}>
    Forgot Password?
  </Link>
);

const PasswordForgetForm = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [error, setError] = useState<Error | undefined>();
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isInvalid = email === "";

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);

    firebase
      .doPasswordReset(email)
      .then(() => {
        setIsSent(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <Card
        maxWidth="calc(100vw - 16px * 2)"
        width={560}
        background="tint1"
        elevation={2}
      >
        <Pane padding={16} borderBottom>
          <Heading size={500} fontSize={20}>
            Password Reset
          </Heading>
        </Pane>
        <Pane padding={16} display="flex" flexDirection="column">
          {error && (
            <Alert intent="danger" title={error.message} marginBottom={10} />
          )}
          {isSent && (
            <Alert
              intent="success"
              title="Password reset e-mail has been sent!"
              marginBottom={10}
            />
          )}
          <TextInput
            disabled={isSent}
            name="email"
            maxWidth="100%"
            value={email}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setEmail(event.currentTarget.value)
            }
            type="text"
            placeholder="Email Address"
          />
        </Pane>
        <Pane display="flex" padding={16} borderTop justifyContent="flex-end">
          <Button
            type="button"
            intent={isSent ? "success" : undefined}
            appearance={isSent ? "primary" : undefined}
            marginRight={!isSent && 20}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          {!isSent && (
            <Button
              disabled={isInvalid}
              type="submit"
              appearance="primary"
              isLoading={isLoading}
            >
              {isLoading ? "Loading..." : "Reset My Password"}
            </Button>
          )}
        </Pane>
      </Card>
    </form>
  );
};

const PasswordForgetPage = () => (
  <div className="landing-page">
    <PasswordForgetForm />
  </div>
);

export default PasswordForgetPage;
export { PasswordForgetForm, PasswordForgetLink };
