import React from "react";
import { TAF } from "@weatheredstrip/shared";

type PropsType = {
  data: TAF[] | null;
};

function Tafs({ data }: PropsType) {
  let tafs: React.ReactNode;

  if (data && data.length > 0) {
    tafs = data.map((taf, index) => (
      <div className="notif-text" key={index}>
        {taf}
      </div>
    ));
  } else {
    tafs = <div>No TAF is issued for this station.</div>;
  }

  return <div className="tafs">{tafs}</div>;
}

export default Tafs;
