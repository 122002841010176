import React, { useContext, useEffect } from "react";
import moment, { Moment } from "moment";
import {
  useAppDispatch,
  useAppSelector,
  useAuthUser,
  usePageVisibility,
  useProfile,
} from "../../hooks";
import { UserTier } from "@weatheredstrip/shared";
import { query, selectStations } from "./querySlice";
import styled from "styled-components";
import { FirebaseContext } from "../../firebase";
import { logEvent } from "firebase/analytics";

type PropsType = {
  dataTime: Date;
};

const timeFormat = "YYYY-MM-DD[T]HH:mm:ss[Z]";

function paddedNumber(num: number): string {
  return num.toString().padStart(2, "0");
}

const LiveClock = ({ currentTime }: { currentTime: Moment }) => {
  return <time>{currentTime.utc().format(timeFormat)}</time>;
};

const automaticUpdateInterval = 60 * 10 + 1;

const AutoUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input {
    margin-left: 0.5rem;
  }
`;

function calculateSecondsLeft(date: Date, currentTime: Moment) {
  return Math.max(
    moment(date)
      .add(automaticUpdateInterval, "second")
      .diff(currentTime, "second"),
    0
  );
}

const renderTimeLeft = (secondsLeft: number) => {
  return `${paddedNumber(Math.floor(secondsLeft / 60))}:${paddedNumber(
    secondsLeft % 60
  )}`;
};

const AutomaticUpdate = ({
  date,
  currentTime,
}: {
  date: Date;
  currentTime: Moment;
}) => {
  const firebase = useContext(FirebaseContext);
  const dispatch = useAppDispatch();
  const currentQuery = useAppSelector(selectStations);
  const { profile, setProfile } = useProfile();
  const secondsLeft = calculateSecondsLeft(date, currentTime);

  const autoUpdate = profile?.autoUpdate;

  const pageVisible = usePageVisibility();

  useEffect(() => {
    if (secondsLeft === 0 && autoUpdate && pageVisible) {
      dispatch(query(currentQuery));
    }
  }, [secondsLeft, currentQuery, dispatch, autoUpdate, pageVisible]);

  return (
    <AutoUpdate>
      <span>
        {autoUpdate && secondsLeft !== 0 && renderTimeLeft(secondsLeft)}
        {autoUpdate && secondsLeft === 0 && "Refreshing..."}
        {!autoUpdate && "Disabled"}
      </span>
      <input
        type="checkbox"
        checked={autoUpdate}
        onChange={() => {
          setProfile({ autoUpdate: !autoUpdate });
          if (!autoUpdate) {
            logEvent(firebase.analytics, "auto_update_selected");
          }
        }}
      />
    </AutoUpdate>
  );
};

function userHasAutoUpdate(user: ReturnType<typeof useAuthUser>): boolean {
  return Boolean(
    user &&
      typeof user.profile.tier === "number" &&
      user.profile.tier >= UserTier.Basic
  );
}

const TimestampContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex: 1;
`;

const AutomaticUpdatePill = styled.div`
  background-color: #e5e5e5;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  border-color: #a1a1a1;
  border-width: 1px;
  border-style: solid;
`;

function Timestamp({ dataTime }: PropsType) {
  const user = useAuthUser();

  const [currentTime, setCurrentTime] = React.useState(moment());
  useEffect(() => {
    const refreshInterval = setInterval(
      () => {
        setCurrentTime(moment());
      },
      1000 // 1 sec
    );

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <TimestampContainer>
      <div className="timestamp">
        <div className="timestamp-names">
          <div>Data time:</div>
          <div>Current time:</div>
          {userHasAutoUpdate(user) && <div>Auto-update:</div>}
        </div>
        <div className="timestamp-time">
          <time>{moment.utc(dataTime).format(timeFormat)}</time>
          <LiveClock currentTime={currentTime} />
          {userHasAutoUpdate(user) && (
            <AutomaticUpdate date={dataTime} currentTime={currentTime} />
          )}
        </div>
      </div>
      {!userHasAutoUpdate(user) && (
        <AutomaticUpdatePill>
          Automatic refresh available when logged in.
        </AutomaticUpdatePill>
      )}
    </TimestampContainer>
  );
}

export default Timestamp;
