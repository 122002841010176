import React from "react";

function Footer() {
  const year = new Date();

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-item">
          Data from{" "}
          {/*<a href="https://flightplanning.navcanada.ca">NAV CANADA</a> */}
          <a href="https://www.aviationweather.gov">AWC</a>&#8480; and{" "}
          <a href="https://notams.aim.faa.gov">FAA</a>&#8480;
        </div>
        <div className="footer-item">
          <div>
            <a href="mailto:admin@weatheredstrip.com?subject=Weathered Strip Issue">
              Report an Issue
            </a>
          </div>
          <div>
            &copy;{" "}
            <a href="http://www.greghamel.com">
              Greg Hamel - {year.getUTCFullYear()}
            </a>
          </div>
        </div>
        <div className="footer-item">
          Made in{" "}
          <span role="img" aria-label="Canada">
            🍁
          </span>{" "}
          with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
