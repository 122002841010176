import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import { withAuthentication } from "../session";

import LandingPage from "../pages/Landing";
import SignUpPage from "../components/SignUp";
import PasswordForgetPage from "../components/PasswordForget";
import AccountPage from "../pages/Account";
import RequestPage from "../pages/Request";
import NoMatchPage from "../pages/NoMatchPage";
import StatusPopup from "../components/StatusPopup";

import ROUTES from "../constants/routes";

const App = () => (
  <>
    <Router>
      <div className="app">
        <Switch>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.REQUEST} component={RequestPage} />
          <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route exact path={ROUTES.PRIVACY_POLICY}>
            <LandingPage privacy />
          </Route>
          <Route exact path={ROUTES.TERMS_OF_SERVICE}>
            <LandingPage terms />
          </Route>
          <Route component={NoMatchPage} />
        </Switch>
      </div>
    </Router>
    <StatusPopup />
  </>
);

export default withAuthentication(App);
