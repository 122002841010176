import {
  ValidIATACode,
  ValidICAOCode,
  isIATACode,
  isICAOCode,
} from "@weatheredstrip/shared";
import { Search } from "history";
import queryString from "query-string";

export const extractStationsFrom = (search: Search): string[] => {
  const rawStations = queryString.parse(search).stations;

  let stationsArray: string[];

  if (Array.isArray(rawStations)) {
    stationsArray = rawStations;
  } else if (typeof rawStations === "string") {
    stationsArray = rawStations
      .split(/(\s|,)/)
      .filter((station) => station !== " ");
  } else {
    stationsArray = [];
  }

  return stationsArray;
};

const validateStationCode = (stations: string): boolean => {
  return isIATACode(stations) || isICAOCode(stations);
};

export const filterStationCodes = (
  stations: string[]
): (ValidICAOCode | ValidIATACode)[] => {
  return stations.filter((potentialCode) =>
    validateStationCode(potentialCode)
  ) as (ValidICAOCode | ValidIATACode)[];
};
