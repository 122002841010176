import {
  CanadianNOTAM,
  GENERIC_AIP_LINK,
  NOTAM,
  isCanadianNOTAM,
} from "@weatheredstrip/shared";
import moment from "moment";
import React from "react";

export function formatEndValidity(value: string) {
  return value === "PERM" || value === null
    ? "PERMANENT"
    : moment.utc(value).format("YYMMDDHHmm");
}

export function formatStartValidity(value: string) {
  return moment.utc(value).format("YYMMDDHHmm");
}

export function Notam({ notam }: { notam: NOTAM }) {
  const aipInText = /AIP SUPPLEMENT/i.test(notam.notam);

  return (
    <div className="notif-text">
      <div className="notam-heading">
        <NotamTitle notam={notam} />
        <NotamValidity notam={notam} />
      </div>
      {isCanadianNOTAM(notam) &&
        notam.schedule?.length > 0 &&
        NotamSchedule(notam)}
      <div className="notam-text">{notam.notam}</div>
      {isCanadianNOTAM(notam) && aipInText && <AIPLink />}
    </div>
  );
}

function NotamValidity({ notam }: { notam: NOTAM }): React.ReactNode {
  const startValidity = new Date(notam.startValidity);
  const now = new Date();
  const isActive = now > startValidity;

  return (
    <div
      className={
        "notam-effective-pill" + (isActive ? " notam-active" : " notam-waiting")
      }
    >
      <div
        tabIndex={0}
        className="notam-time"
        title={`NOTAM Effective Start (UTC) - ${moment
          .utc(notam.startValidity)
          .fromNow()}`}
      >
        {formatStartValidity(notam.startValidity)}
      </div>
      <div
        tabIndex={1}
        className="notam-time notam-time-more"
        title={`NOTAM Effective End (UTC) - ${
          notam.endValidity === "PERM"
            ? "PERM"
            : moment.utc(notam.endValidity).fromNow()
        }`}
      >
        {formatEndValidity(notam.endValidity)}
      </div>
      {notam.isEndEstimated && (
        <div
          tabIndex={2}
          className="notam-time notam-time-more"
          title="NOTAM Effective End is estimated"
        >
          EST.
        </div>
      )}
    </div>
  );
}

function NotamTitle({ notam }: { notam: NOTAM }): React.ReactNode {
  return notam && notam.link ? (
    <div>
      <a href={notam.link} rel="noopener noreferrer" target="_blank">
        <strong>{notam.title} - </strong>
        <i className="fas fa-external-link-alt"></i>
      </a>
    </div>
  ) : (
    <div className="notam-title">{notam.title}</div>
  );
}

export function NotamSchedule(notam: CanadianNOTAM): React.ReactNode {
  return (
    <div className="notam-text">
      {notam.schedule.map((schedule, index) => (
        <div key={index}>{schedule}</div>
      ))}
    </div>
  );
}

function AIPLink() {
  return (
    <div className="notam-text">
      <a href={GENERIC_AIP_LINK} target="_blank" rel="noopener noreferrer">
        AIP Supplements
        <i
          className="fas fa-external-link-alt"
          style={{ marginLeft: "0.5em" }}
        ></i>
      </a>
    </div>
  );
}
