import { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { getDoc, updateDoc } from "firebase/firestore";

import Firebase, { FirebaseContext } from "../firebase";
import { UserProfile } from "@weatheredstrip/shared";
import { AppDispatch, RootState } from "../store";

const getProfileDocFor = (userId: string, firebase: Firebase) => {
  return getDoc(firebase.userProfileReferenceFor(userId));
};

export const useProfile = () => {
  const firebase = useContext(FirebaseContext);
  const [profile, loading] = useDocument(
    firebase.userProfileReferenceFor(firebase.auth.currentUser?.uid || "")
  );

  return {
    profile: profile?.data(),
    setProfile: async (data: Partial<UserProfile>) => {
      await updateDoc(
        firebase.userProfileReferenceFor(firebase.auth.currentUser?.uid || ""),
        data
      );
    },
    loading,
  };
};

export const useAuthUser = () => {
  const firebase = useContext(FirebaseContext);
  const [authUser, authLoading] = useAuthState(firebase.auth);

  const [userProfile, setUserProfile] = useState<Partial<UserProfile>>({});
  const [infoLoading, setInfoLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async (userId: string) => {
      setInfoLoading(true);
      try {
        const doc = await getProfileDocFor(userId, firebase);

        if (doc.exists()) {
          setUserProfile(doc.data() as Partial<UserProfile>);
        }
      } catch (e) {
        console.log(e);
      }
      setInfoLoading(false);
    };

    if (authUser?.uid) {
      fetchUserData(authUser.uid);
    } else {
      setUserProfile({});
    }
  }, [authUser?.uid, firebase]);

  if (!authUser) {
    return authUser;
  }

  return {
    ...authUser,
    profile: userProfile,
    loading: authLoading || infoLoading,
  };
};

export function usePrevious<T>(value: T): T | undefined {
  const currentRef = useRef<T>(value);
  const previousRef = useRef<T | undefined>();
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }
  return previousRef.current;
}

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export function usePageVisibility() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return visible;
}
