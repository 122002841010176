import React from "react";
import SearchBox from "./searchbox";
import Navigation from "../../components/Navigation";
import { Link } from "react-router-dom";
import { Pane } from "evergreen-ui";

import * as ROUTES from "../../constants/routes";

const Header = () => {
  return (
    <header className="app-header">
      <div className="app-header-content">
        <Link to={ROUTES.LANDING}>
          <div id="app-info">
            <img id="app-logo" src={"/logo.svg"} alt="App Logo" />
            <div className="app-title">Weathered Strip</div>
          </div>
        </Link>
        <Pane className="search-nav">
          <SearchBox />
          <Navigation right="20px" />
        </Pane>
      </div>
    </header>
  );
};

export default Header;
