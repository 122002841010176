import React, { useContext, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Alert,
  TextInput,
  Heading,
  Button,
  Pane,
  Text,
  Checkbox,
  Link,
  Card,
} from "evergreen-ui";

import { setDoc } from "firebase/firestore";

import { FirebaseContext } from "../../firebase";
import { ToSDialogLink, PrivacyPolicyDialogLink } from "../Policies";
import * as ROUTES from "../../constants/routes";
import { UserTier } from "@weatheredstrip/shared";

const SignUpLink = () => (
  <Text>
    Don't have an account?{" "}
    <Link is={RouterLink} to={ROUTES.SIGN_UP}>
      Sign Up
    </Link>
  </Text>
);

const SignUpForm = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const [readBoth, setReadBoth] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    email === "" ||
    firstName === "" ||
    lastName === "" ||
    !readBoth;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in Firestore

        return setDoc(firebase.userProfileReferenceFor(authUser.user.uid), {
          firstName,
          lastName,
          email,
          shortcuts: [],
          tier: UserTier.Basic,
        });
      })
      .then(() => {
        /* Add firstName as Display Name to firebase authentication
         * this will be used by authentication templates */
        return firebase.doAddProfileName(firstName);
      })
      .then(() => {
        return firebase.doSendEmailVerification();
      })
      .then(() => {
        setIsComplete(true);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
    event.preventDefault();
  };

  return (
    <>
      <Card
        maxWidth="calc(100vw - 16px * 2)"
        width={560}
        background="tint1"
        elevation={2}
      >
        <Pane padding={16} borderBottom>
          <Heading size={500} fontSize={20}>
            Sign Up
          </Heading>
        </Pane>
        <form onSubmit={onSubmit}>
          <Pane
            padding={16}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            {error ? (
              <Alert intent="danger" title={error.message} marginBottom={10} />
            ) : isComplete ? (
              <Alert intent="success" title="Welcome, abord!" marginBottom={10}>
                You'll need to verify your e-mail before having access to
                further account settings. Check your inbox, we'll be right here
                when you get back.
              </Alert>
            ) : (
              <>
                <Text>
                  Accounts will provide the ability to add presets in the
                  future.
                </Text>
                <Text marginBottom={20}>
                  Option for feature update emails will also be provided.
                </Text>
              </>
            )}
            {!isComplete && (
              <>
                <TextInput
                  name="firstName"
                  value={firstName}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setFirstName(event.currentTarget.value)
                  }
                  type="text"
                  placeholder="First Name"
                  marginBottom={10}
                  maxWidth="100%"
                />
                <TextInput
                  name="lastName"
                  value={lastName}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setLastName(event.currentTarget.value)
                  }
                  type="text"
                  placeholder="Last Name"
                  marginBottom={10}
                  maxWidth="100%"
                />
                <TextInput
                  name="email"
                  value={email}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setEmail(event.currentTarget.value)
                  }
                  type="text"
                  placeholder="Email Address"
                  marginBottom={10}
                  maxWidth="100%"
                />
                <TextInput
                  name="passwordOne"
                  value={passwordOne}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setPasswordOne(event.currentTarget.value)
                  }
                  type="password"
                  placeholder="Password"
                  marginBottom={10}
                  maxWidth="100%"
                />
                <TextInput
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    setPasswordTwo(event.currentTarget.value)
                  }
                  type="password"
                  placeholder="Confirm Password"
                  marginBottom={10}
                  maxWidth="100%"
                />
                <Pane display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    marginRight={20}
                    name="readBoth"
                    checked={readBoth}
                    onChange={() => setReadBoth(!readBoth)}
                    label={
                      <Text>
                        I have read the <ToSDialogLink /> and{" "}
                        <PrivacyPolicyDialogLink />
                      </Text>
                    }
                  />
                </Pane>
              </>
            )}
          </Pane>
          <Pane display="flex" padding={16} borderTop justifyContent="flex-end">
            <Button
              marginRight={!isComplete ? 20 : null}
              appearance={!isComplete ? undefined : "primary"}
              intent={!isComplete ? undefined : "success"}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            {!isComplete && (
              <Button
                appearance="primary"
                disabled={isInvalid}
                type="submit"
                isLoading={isLoading}
              >
                {isLoading ? "Loading..." : "Sign Up"}
              </Button>
            )}
          </Pane>
        </form>
      </Card>
    </>
  );
};

const SignUpPage = () => (
  <div className="landing-page">
    <SignUpForm />
  </div>
);

export default SignUpPage;
export { SignUpForm, SignUpLink };
