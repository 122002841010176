import { StatusRecord } from "@weatheredstrip/shared";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { FirebaseContext } from "../firebase";
import {
  CollectionReference,
  collection,
  onSnapshot,
} from "firebase/firestore";

interface IStatusContext {
  records: StatusRecord[];
}

const StatusContext = createContext({} as IStatusContext);

export const StatusProvider = ({ children }: { children: ReactNode }) => {
  const [records, setRecords] = useState<StatusRecord[]>([]);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    const statusCollectionRef = collection(
      firebase.firestore,
      "status"
    ) as CollectionReference<StatusRecord, StatusRecord>;
    const unsubscribe = onSnapshot(statusCollectionRef, (snapshot) => {
      const statusRecords: StatusRecord[] = [];
      snapshot.forEach((record) => {
        statusRecords.push(record.data());
      });
      setRecords(statusRecords);
    });

    return () => {
      unsubscribe();
    };
  }, [firebase.firestore]);

  return (
    <StatusContext.Provider value={{ records }}>
      {children}
    </StatusContext.Provider>
  );
};

export const useStatusContext = (): IStatusContext => useContext(StatusContext);
