import React, { useContext } from "react";

import { FirebaseContext } from "../../firebase";
import { Menu, LogOutIcon } from "evergreen-ui";

const SignOutButton = () => {
  const firebase = useContext(FirebaseContext);

  return (
    <Menu.Item onSelect={firebase.doSignOut} icon={LogOutIcon}>
      Sign Out
    </Menu.Item>
  );
};

export default SignOutButton;
