import React, { useContext, useState } from "react";
import { TextInput, Button, Pane, Alert } from "evergreen-ui";

import { FirebaseContext } from "../../firebase";

const PasswordChangeForm = () => {
  const firebase = useContext(FirebaseContext);

  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        setIsComplete(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <Pane display="flex" flexDirection="column" marginTop={10}>
        {error && (
          <Alert intent="danger" title={error.message} marginBottom={10} />
        )}
        {isComplete && (
          <Alert
            intent="success"
            title="The password was changed successfully"
            marginBottom={10}
          />
        )}
        <TextInput
          name="passwordOne"
          value={passwordOne}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setPasswordOne(event.currentTarget.value)
          }
          type="password"
          placeholder="New Password"
          marginBottom={10}
          maxWidth="100%"
        />
        <TextInput
          name="passwordTwo"
          value={passwordTwo}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setPasswordTwo(event.currentTarget.value)
          }
          type="password"
          placeholder="Confirm New Password"
          marginBottom={10}
          maxWidth="100%"
        />
        <Button disabled={isInvalid || isLoading} type="submit" width={160}>
          Change My Password
        </Button>
      </Pane>
    </form>
  );
};

export default PasswordChangeForm;
