import { GFARegion } from "@weatheredstrip/shared";

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

const std_parallel_1 = deg2rad(0);
const std_parallel_2 = deg2rad(90);
const central_parallel = deg2rad(49);

const region_def = {
  [GFARegion.Pacific]: {
    central_meridian: deg2rad(-120),
    point_references: [
      { gfa_coord: [508.5, 430.5], lon_lat: [-112.8, 49.63] },
      { gfa_coord: [113.5, 54.5], lon_lat: [-139.041, 61.371] },
    ],
  },
  [GFARegion.Prairies]: {
    central_meridian: deg2rad(-105),
    point_references: [
      { gfa_coord: [58.5, 55.5], lon_lat: [-121.236, 61.76] },
      { gfa_coord: [509.5, 408.5], lon_lat: [-92.744, 49.832] },
    ],
  },
  [GFARegion.Ont_Que]: {
    central_meridian: deg2rad(-80),
    point_references: [
      { gfa_coord: [68.5, 114.5], lon_lat: [-94.711, 56.357] },
      { gfa_coord: [485.5, 460.5], lon_lat: [-71.691, 45.438] },
    ],
  },
  [GFARegion.Atlantic]: {
    central_meridian: deg2rad(-54),
    point_references: [
      { gfa_coord: [112.5, 25.5], lon_lat: [-69.956, 58.667] }, // CYTQ
      { gfa_coord: [376.5, 387.5], lon_lat: [-52.752, 47.619] }, // CYYT
    ],
  },
  [GFARegion.Nunavut]: {
    central_meridian: deg2rad(-80),
    point_references: [
      { gfa_coord: [490.5, 585.5], lon_lat: [-66.805, 54.805] }, // CYKL
      { gfa_coord: [171.5, 52.5], lon_lat: [-94.969, 74.717] }, // CYRB
    ],
  },
  [GFARegion.Yukon]: {
    central_meridian: deg2rad(-120),
    point_references: [
      { gfa_coord: [538.5, 81.5], lon_lat: [-84.614, 72.982] }, // CYSR
      { gfa_coord: [106.5, 492.5], lon_lat: [-132.742, 60.172] }, // CYZW
    ],
  },
  [GFARegion.Arctic]: {
    central_meridian: deg2rad(-90),
    point_references: [
      { gfa_coord: [417.5, 186.5], lon_lat: [-62.281, 82.518] }, // CYLT
      { gfa_coord: [69.5, 551.5], lon_lat: [-115.144, 67.817] }, // CYCO
    ],
  },
};

const calculate_lambert = (
  longitude: number,
  latitude: number,
  region: GFARegion
) => {
  // Based on https://www12.statcan.gc.ca/census-recensement/2021/ref/dict/az/Definition-eng.cfm?ID=geo031

  const central_meridian = region_def[region].central_meridian;

  const lambda = deg2rad(longitude);
  const phi = deg2rad(latitude);

  const n =
    Math.log(Math.cos(std_parallel_1) * (1 / Math.cos(std_parallel_2))) /
    Math.log(
      Math.tan(Math.PI / 4 + std_parallel_2 / 2) /
        Math.tan(Math.PI / 4 + std_parallel_1 / 2)
    );
  const RF =
    (6378 *
      Math.cos(std_parallel_1) *
      Math.pow(Math.tan(Math.PI / 4 + std_parallel_1 / 2), n)) /
    n;
  const rho = RF * Math.pow(1 / Math.tan(Math.PI / 4 + phi / 2), n);
  const rho0 =
    RF * Math.pow(1 / Math.tan(Math.PI / 4 + central_parallel / 2), n);

  const x = rho * Math.sin(n * (lambda - central_meridian));
  const y = rho0 - rho * Math.cos(n * (lambda - central_meridian));

  return [x, y];
};

export const calculate_GFA_coordinates = (
  longitude: number,
  latitude: number,
  region: GFARegion
) => {
  const region_properties = region_def[region].point_references;

  const POINT_1 = region_properties[0].gfa_coord;
  const POINT_2 = region_properties[1].gfa_coord;

  const POINT_1_prime = calculate_lambert(
    region_properties[0].lon_lat[0],
    region_properties[0].lon_lat[1],
    region
  );
  const POINT_2_prime = calculate_lambert(
    region_properties[1].lon_lat[0],
    region_properties[1].lon_lat[1],
    region
  );

  const alpha =
    (POINT_2[1] - POINT_1[1]) / (POINT_2_prime[1] - POINT_1_prime[1]);
  const a = POINT_1[1] - alpha * POINT_1_prime[1];

  const beta =
    (POINT_2[0] - POINT_1[0]) / (POINT_2_prime[0] - POINT_1_prime[0]);
  const b = POINT_1[0] - beta * POINT_1_prime[0];

  const [x, y] = calculate_lambert(longitude, latitude, region);

  return [beta * x + b, alpha * y + a];
};
